.project_card{
    max-width: 345px;
    margin-top: 5vw;
}

.card_media{
    height: 140px;
}

@media (min-width: 1025px) {
    .project_card{
        min-width: 80vw;
        margin-top: 1vw;
    }
    .card_media{
        height: 50vh;
        img{
            object-fit: scale-down !important;
        }
    }
}