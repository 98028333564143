.quoteOuterDiv{
    color: rgb(212, 183, 212);
    padding: 5vw;
    
    .informer{
        color: white;
        text-align: start;
    }
    
    .quote{
        font-style: italic;
        text-align: left;
    }
}