.sentence {
    font-style: italic;
    text-align: left;
    padding-left: 10vw;
    padding-right: 10vw;
    white-space: pre-wrap;
}

.verifyStatement {
    text-align: right;
    margin-right: 5vw;
    margin-top: 3vw;
    font-style: italic;
    color: #bcbcbc;

    .verifyLink {
        text-decoration: underline;
        color: #bcbcbc;
    }
}

.quote-start,
.quote-end {
    width: 15vw;
    margin-top: 5vw;
    margin-left: 5vw;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.quote-end {
    rotate: 0deg;
    margin-left: auto;
    margin-right: 5vw;
    margin-top: 0;
    -webkit-transform: none;
    transform: none;
}

.profileOuterClass {
    margin-top: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recommenderName {
    font-size: 7vw;
}
.profileInnerClass {
    padding-left: 5vw;
    text-align: left;
}

.recommenderDesignation {
    font-weight: bold;
    color: rgb(156, 217, 229);
    color: rgb(119, 210, 249);
}

@media (min-width: 1025px) {
    .recommenderName {
        font-size: 2vw;
    }
    .profileInnerClass{
        padding-left: 1vw;
    }
    .profileOuterClass{
        margin-top: 5vw;
    }
    .quote-start, .quote-end{
        width: 5vw;
    }
}
