.contact_outer_div {
    margin-top: 10vw;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15vw;
}

.contact_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5vw;
}

.contact_icon{
    margin-right: 5vw;
    width: 5vh;
}

@media (min-width: 1025px) {
    .contact_div {
        margin-top: 2vw;
        // border: 2px solid red;
        max-width: 30%;
        justify-content: start;
        margin-left: 25vw;
        cursor: pointer;

    }
    .contact_outer_div{
        margin-top: 1vw;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1vw;
        // border: 2px solid red;
    }

    .contact_icon{
        margin-right: 1vw;
    }
}
