@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Add this to your CSS file */
.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.fade-in-visible {
    opacity: 1;
}