// For ordinary screens

.carousel_box {
    max-width: 400px;
    flex-grow: 1;
}

.carousel_paper {
    display: "flex";
    align-items: "center";
    height: 50;
    margin-top: 15vw;
}

.image_desc {
    padding: 2vw;
    text-align: center;
}

.swipeable_images{
    height: 255px;
    display: block;
    max-width: 400px;
    overflow: hidden;
    width: 100%;
    object-fit: cover;
    // background: blue;
}

// For laptops
@media (min-width: 1025px) {
    .carousel_box {
        width: 90vw;
    }
    .carousel_paper {
        width: 90vw;
        margin-top: 3vw;
    }
    .swipeable_view{
        width: 90vw;
        // border: 2px solid blue;
    }
    .carousel_box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        margin-left: 5vw;
    }

    .swipeable_images{
        height: 50vh;
        display: block;
        max-width: 90vw;
        overflow: hidden;
        width: 100%;
        object-fit: contain;
        background: #121212;
        background: rgb(41, 37, 37);
        border-radius: 0.5vw;
    }

    .bottom_bar{
        // border: 2px solid red;
        width: 90vw;
    }
}
