// For ordinary screens
.menu_icon{
    width: 5vw;
}

.sections_icon{
    width: 10vw;
}

.menu_item{
    padding: 5vw;
}

// For laptops
@media (min-width: 1025px) {
    .menu_icon{
        width: 2vw;
    }
    .sections_icon{
        width: 2vw;
    }
    .menu_item{
        padding: 1vw;
    }
}
