.project_section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10vw;
}

@media (min-width: 1025px) {
    .project_section{
        margin-top: 1vw;
        // width: 90vw;
        // border: 2px solid white;
    }
}